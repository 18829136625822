<!-- <template>
  <el-dialog
    title="详情"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
  >
    <el-form
      :model="formData"
      ref="form"
      label-width="100px"
      size="mini"
      v-loading="loading"
    >
      <el-form-item label="提现金额">
        <div>{{ formData.money }}</div>
      </el-form-item>
      <el-form-item label="银行卡号">
        <div>{{ formData.cardNumber }}</div>
      </el-form-item>
      <el-form-item label="开户名称">
        <div>{{ formData.accountName }}</div>
      </el-form-item>
      <el-form-item label="开户行">
        <div>{{ formData.openBank }}</div>
      </el-form-item>
      <el-form-item label="开户地址" verify>
        <div>{{ formData.address }}</div>
      </el-form-item>
      <el-form-item label="发票图片" verify>
        <template>
          <el-image
            style="width: 100px; height: 100px"
            :src="formData.invoiceImage"
            fit="cover"
            :preview-src-list="[formData.invoiceImage]"
          />
        </template>
      </el-form-item>
    </el-form>
    <gl-flex>
      <el-button @click="handleClose">取消</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
export default {
  props: {
    itemId: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        invoiceImage: 'https://img01.yzcdn.cn/vant/apple-1.jpg',
      },
      loading: false,
    }
  },
  watch: {
    itemId: {
      handler: function (newVal, oldval) {
        this.loading = true
        setTimeout(() => {
          this.loading = false
        }, 1000)
      },
    },
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style></style> -->
<template>
  <el-dialog
    title="详情"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="80%"
  >
    <!-- <el-form :model="formData" ref="form" label-width="100px" size="mini" v-loading="loading">
      <el-form-item label="提现金额">
        <div> {{formData.money}} </div>
      </el-form-item>
      <el-form-item label="银行卡号">
        <div> {{formData.cardNumber}} </div>
      </el-form-item>
      <el-form-item label="开户名称">
        <div> {{formData.accountName}} </div>
      </el-form-item>
      <el-form-item label="开户行">
        <div> {{formData.openBank}} </div>
      </el-form-item>
      <el-form-item label="开户地址" verify>
        <div> {{formData.address}} </div>
      </el-form-item>
      <el-form-item label="发票图片" verify>
        <template>
          <el-image
            style="width: 100px; height: 100px"
            :src="formData.invoiceImage"
            fit="cover"
            :preview-src-list="[formData.invoiceImage]"
          />
        </template>
      </el-form-item>
    </el-form>  -->

    <el-table :data="mTableData" style="width: 100%" ref="multipleTable">
      <el-table-column
        type="index"
        :index="mIndexMethod"
        label="序号"
        align="center"
        min-width="30"
      ></el-table-column>
      <el-table-column
        prop="orderId"
        label="订单ID"
        align="center"
        min-width="100"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column
        prop="balanceExtractId"
        label="提现ID"
        align="center"
        min-width="100"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column
        prop="itemName"
        label="商品标题"
        align="center"
        min-width="200"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column
        prop="skuSize"
        label="商品规格"
        align="center"
        min-width="60"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column
        prop="userName"
        label="下单人昵称"
        align="center"
        min-width="70"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column
        prop="bringMoney"
        label="提现金额"
        align="center"
        min-width="50"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="serviceCharge"
        label="手续费"
        align="center"
        min-width="50"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="realityMoney"
        label="到账金额"
        align="center"
        min-width="50"
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <gl-pagination
      :total="mTotal"
      :current-page="mPageNum"
      @currentChange="mHandleCurrentChange"
      @sizeChange="mHandleSizeChange"
    >
    </gl-pagination>

    <gl-flex>
      <el-button @click="handleClose">关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import { getOrderList } from '@/api/balance.js'
import ListMixin from '@/mixins/list.mixin.js'

export default {
  mixins: [ListMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    balanceExtractId: {
      type: String,
      default: '',
    },
  },
  mounted() {},
  data() {
    return {
      formData: {
        invoiceImage: 'https://img01.yzcdn.cn/vant/apple-1.jpg',
      },
      loading: false,
      balanceId: null,
    }
  },
  watch: {
    balanceExtractId: {
      handler: function (newVal) {
        this.balanceId = newVal
        this.getDetail()
        this.mParams['balanceExtractId'] = this.balanceId
        this.mGetListFun = getOrderList
        this.mGetList()
        // newVal && this.visible && this.getDetail()
      },
    },
  },
  methods: {
    getDetail() {
      this.loading = true

      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    handleClose() {
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style></style>
