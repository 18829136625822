<!-- <template>
  <el-dialog 
    title="新增"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="838px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="100px" width="500px" size="mini" v-loading="loading" >
        <el-form-item prop="money" label="提现金额" verify>
          <el-input v-model="formData.money"></el-input>
        </el-form-item>
        <el-form-item prop="cardNumber" label="银行卡号" verify>
          <el-input v-model="formData.cardNumber"></el-input>
        </el-form-item>
        <el-form-item prop="accountName" label="开户名称" verify>
          <el-input v-model="formData.accountName"></el-input>
        </el-form-item>
        <el-form-item prop="openBank" label="开户行" verify>
          <el-input v-model="formData.openBank"></el-input>
        </el-form-item>
        <el-form-item prop="address" label="开户地址" verify>
          <el-input v-model="formData.address"></el-input>
        </el-form-item>
        <el-form-item prop="invoiceImage" label="发票图片" verify :watch="formData.invoiceImage" > 
          <gl-upload-images 
            :limit="6" 
            v-model="formData.invoiceImage" 
            ref="uploadImages" 
            :oldList="oldList" 
            :autoUpload="true">
          </gl-upload-images>
        </el-form-item>
        <el-form-item prop="content" label="富文本" verify>
          <gl-editor v-model="formData.content"></gl-editor>
        </el-form-item>
      </el-form> 
    </gl-wrap>

    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
export default {
  name: 'add',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      formData: {
        // invoiceImage: '',
        content: '<p>模拟 Ajax 异步设置内容55555555 HTML</p>'
      },
      oldList: [
        // 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
        // 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      ],
      loading: false,
    }
  },
  methods: {
    async onSubmit(){
      // const imgs = await this.$refs.uploadImages.upload()
      // console.error('imgs:', imgs)
      console.log("formData:", this.formData)
      // return
      this.$refs.form.validate(async valid => {
        if(!valid) return false
        console.log('formData:', this.formData)

        this.loading = true
        // const res = null
        // if(this.itemId){
        //   res = await update(this.formData)
        // }else{
        //   res = await add(this.formData)
        // }
        setTimeout(() => {
          this.loading = false
          this.$message.success('操作成功')
          this.handleClose()
          this.$emit('reload')
        },100)
      })   
      
    },
    handleClose(){
      this.$emit('update:visible', false)
      setTimeout(() => {
        // this.formData = {}
        this.$refs.form.resetFields()
      }, 100)
    },
  }
}
</script>

<style>

</style> -->

<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-03-13 09:27:41
 * @LastEditors: pj
 * @LastEditTime: 2023-04-19 14:48:40
-->
<template>
  <el-dialog
    title="新增"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="640px"
    close="add-apply-dialog"
  >
    <!--内嵌的第二层弹窗-->
    <el-dialog
      width="70%"
      title="提现订单列表"
      :visible.sync="innerVisible"
      :close-on-click-modal="false"
      append-to-body
    >
      <div class="dialogButton">
        <div class="dialogButton-all" @click="confirm((type = 'all'))">
          全部提现
        </div>
        <div class="dialogButton-confirm" @click="confirm">确认</div>
        <div class="dialogButton-cancel" @click="innerVisible = false">
          取消
        </div>
      </div>

      <el-table :data="mTableData" style="width: 100%" ref="multipleTable">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          type="index"
          :index="mIndexMethod"
          label="序号"
          align="center"
          min-width="30"
        ></el-table-column>
        <el-table-column
          prop="orderId"
          label="订单ID"
          align="center"
          min-width="100"
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column
          prop="itemName"
          label="商品标题"
          align="center"
          min-width="100"
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column
          prop="skuName"
          label="商品规格"
          align="center"
          min-width="60"
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column
          prop="buyerName"
          label="下单人昵称"
          align="center"
          min-width="70"
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column
          prop="totalPrice"
          label="金额"
          align="center"
          min-width="50"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange"
      >
      </gl-pagination>
    </el-dialog>
    <gl-wrap right="30px">
      <el-form
        :model="formData"
        ref="form"
        label-width="100px"
        width="500px"
        size="mini"
        v-loading="loading"
      >
        <el-form-item prop="money" label="提现金额" verify>
          <div class="formData-money">
            <div class="formData-money-text">{{ formData.money }}</div>
            <div class="formData-money-openList" @click="innerVisible = true">
              选择提现订单>>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="cardNumber" label="银行卡号" verify>
          <el-input v-model="formData.cardNumber"></el-input>
        </el-form-item>
        <el-form-item prop="accountName" label="开户名称" verify>
          <el-input v-model="formData.accountName"></el-input>
        </el-form-item>
        <el-form-item prop="openBank" label="开户行" verify>
          <el-input v-model="formData.openBank"></el-input>
        </el-form-item>
        <el-form-item prop="address" label="开户地址" verify>
          <el-input v-model="formData.address"></el-input>
        </el-form-item>
        <!-- <el-form-item
          prop="invoiceImage"
          label="发票图片"
          verify
          :watch="formData.invoiceImage"
        >
          <gl-upload-images
            :limit="6"
            v-model="formData.invoiceImage"
            ref="uploadImages"
            :oldList="oldList"
            :autoUpload="true"
          >
          </gl-upload-images>
        </el-form-item> -->
      </el-form>
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit">确认</el-button>
      <el-button @click="handleClose">关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import { getSettlementExtractList, extractApply } from '@/api/balance.js'
import ListMixin from '@/mixins/list.mixin.js'

export default {
  mixins: [ListMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        money: 0,
      },
      loading: false,
      oldList: [],
      innerVisible: false, // 内嵌的选择体现订单
      orderList: [],
      applyType: null,
    }
  },
  mounted() {
    this.mGetListFun = getSettlementExtractList
    this.mGetList()
  },
  methods: {
    async onSubmit() {
      if (!this.applyType) {
        this.$message({
          message: '提现金额大于0',
          type: 'warning',
        })
        return
      }
      this.$refs.form.validate(async (valid) => {
        if (!valid) return false
        const formDataList = this.formData
        const params = {
          moneyBalanceExtract: {
            bringMoney:
              formDataList.money === '全部金额' ? 0 : formDataList.money,
            bankName: formDataList.accountName,
            accountNumber: formDataList.cardNumber,
            openAccountName: formDataList.openBank,
            openAccountAddress: formDataList.address,
          },
          orderIds: this.orderList,
          applyType: this.applyType,
        }
        const result = await extractApply(params)
        if (result.code == 200) {
          this.loading = true
          setTimeout(() => {
            this.loading = false
            this.$message.success('操作成功')
            this.$emit('reload')
            this.handleClose()
          }, 100)
        }
      })
    },
    handleClose() {
      // this.$nextTick(() => {
      //   this.$refs.form.resetFields()
      // })
      this.$emit('update:visible', false)
    },

    confirm(type) {
      this.formData.money = 0
      let targetList = this.$refs.multipleTable.selection
      if (targetList.length == 0) {
        this.$message({
          message: '至少选择一条',
          type: 'warning',
        })
        return
      }
      if (type == 'all') {
        this.applyType = 2
        this.innerVisible = false
        this.formData.money = '全部金额'
        return
      }
      let orderSet = new Set()

      targetList.forEach((element) => {
        orderSet.add(element.orderId)
        this.formData.money += element.totalPrice
      })
      this.orderList = Array.from(orderSet)
      this.applyType = 1
      this.innerVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.formData {
  &-money {
    display: flex;
    &-text {
      font-family: PingFang SC;
      font-size: 36px;
      color: #3d3d3d;
      margin-right: 10px;
    }
    &-openList {
      font-size: 14px;
      text-decoration: underline;
      color: #409eff;
      cursor: pointer;
    }
  }
}
.dialogButton {
  display: flex;
  &-all {
    width: 78px;
    height: 28px;
    background-color: #409eff;
    border-radius: 3px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
  }
  &-confirm {
    width: 58px;
    height: 28px;
    background-color: #409eff;
    border-radius: 3px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
  }
  &-cancel {
    width: 58px;
    height: 28px;
    background-color: #fff;
    border: 1px #dcdfe6 solid;
    border-radius: 3px;
    color: #3d3d3d;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
  }
}
</style>
